import { useLazyQuery, useMutation } from '@apollo/client';
import { uniqBy } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form, Grid, Item, Popup } from 'semantic-ui-react';

import { InputButton, InputField, InputSelect } from '../../../components/controls';
import RadioList from '../../../components/controls/form/RadioList/RadioList';
import { PPTable, SortDirectionProp, TableColumnProps } from '../../../components/controls/ProviderTable';
import { Loading, ModalPopup, ModalPopupProps } from '../../../components/shared';
import { GlobalContext } from '../../../context/GlobalContext';
import { useDownloadFile, useProviders } from '../../../hooks';
import {
  mutationClosePatientMissingInfoRecord,
  mutationSendNextAttemptNotification,
  mutationUpdatePatientMissingInfoReceivedAll,
  queryDownloadMissingInfoContactingCSV,
  queryDownloadPDFPatientMissingInfo,
  queryPatientProviderContacting,
} from '../../../services';
import { PatientProviderContactingWorklist, Provider, SearchOptions } from '../../../types';
import {
  DEFAULT_NUMBER_OF_RECORDS_PER_PAGE_MISSING_INFORMATION,
  GENERAL_CONSTANTS,
  PAGE_LINKS,
  SORT_ORDERS,
  TABLE_MISSING_INFO_PAGE_COUNTS,
} from '../../../utilities/constants';
import { DateFormat, dateFormat, formatDateToCustomString, transformSearchedParamEntries } from '../../../utilities/sharedFunctions';
import ModalPopupContacting, { ModalPopupContactingProps } from './ModalPopupContacting';

import './PatientProviderContacting.scss';

type ContactingProps = {
  note: string;
  onWorklistUpdate: (totalRecords?: number) => void;
  refreshTabCounts?: () => void;
};

const MODAL_MESSAGE_BODY = {
  RECEIVED_ALL:
    'Are you sure you want to indicate that we have now received all of the Missing Information for this Sales Order? This action will close the patient out of the Missing Information system. If so, click "Yes" and update the WIP state of the Sales Order in Brightree as needed accordingly.',
  CLOSE:
    'Are you sure you want to close this Missing Information record? If so, click "Close" and update the WIP state of the Sales Order in Brightree as needed accordingly.',
  SEND_NEXT_ATTEMPT_NOTIFICATION: `Are you sure you want to trigger a notification to this patient's provider about their missing information? Please make sure the patient's Missing Information record is up to date before sending.`,
  RETURN_TO_FINAL_REVIEW_LIST: `This Missing Information record is on Final Review, so it cannot be returned to the Contacting list. Press "Send to Final Review List" to remove the record from this list and move it to Final Review. If the record is not on Final Review, the "Next Attempt #" will need to be manually updated in the record before you can send it to the Contacting list. `,
};

const SEARCH_FIELD_MAPPER = {
  patientBrightreeId: 'patientBrightreeId',
  mrn: 'mrn',
  nextAttempt: 'nextAttempt',
  nextContactDate: 'nextContactDate',
  providerName: 'providerName',
  providerGroup: 'providerGroup',
  failed: 'failed',
  missing: 'missing',
  received: 'received',
  closed: 'closed',
  open: 'open',
};

const FILTER_BY_RADIO_OPTIONS = [
  { text: 'Missing', value: SEARCH_FIELD_MAPPER.missing, page: PAGE_LINKS.missingInformationContacting },
  { text: 'Received', value: SEARCH_FIELD_MAPPER.received, page: PAGE_LINKS.missingInformationContacting },

  { text: 'Open', value: SEARCH_FIELD_MAPPER.open, page: PAGE_LINKS.missingInformationFinalReview },
  { text: 'Closed', value: SEARCH_FIELD_MAPPER.closed, page: PAGE_LINKS.missingInformationFinalReview },
];

const FILTER_BY_NEXT_ATTEMPT = [
  { text: 'Show all', value: GENERAL_CONSTANTS.all },
  { text: '1st', value: '1st' },
  { text: '2nd', value: '2nd' },
  { text: '3rd', value: '3rd' },
];

const FILTER_BY_NEXT_CONTACT_DATE = [
  { text: 'Show all', value: GENERAL_CONSTANTS.all },
  { text: 'Today (or earlier)', value: 'today_or_earlier' },
];

const FILTER_BY_NOTIFICATION_STATUS = [
  { text: 'Show all', value: GENERAL_CONSTANTS.all },
  { text: 'Attempt in progress', value: 'attempt_in_process' },
];

const SORT_FIELDS = {
  createdAt: 'createdAt',
  nextContactDate: 'nextContactDate',
  receivedDate: 'receivedDate',
  closedDate: 'closedDate',
  nextAttemptSelectedDate: 'nextAttemptSelectedDate',
  referralContactNotificationFailedDate: 'referralContactNotificationFailedDate',
};

const FILTER_BY_DROPDOWN_OPTIONS = [
  {
    value: SEARCH_FIELD_MAPPER.providerName,
    text: 'Provider Name',
  },
  {
    value: SEARCH_FIELD_MAPPER.providerGroup,
    text: 'Provider Group',
  },
  {
    value: SEARCH_FIELD_MAPPER.patientBrightreeId,
    text: 'Patient Brightree ID',
  },
  {
    value: SEARCH_FIELD_MAPPER.mrn,
    text: 'Patient MRN',
  },
];

const PatientProviderContacting = ({ note, onWorklistUpdate, refreshTabCounts }: ContactingProps) => {
  const { user: currentLoggedInUser, logout } = useContext(GlobalContext);

  const userRole = currentLoggedInUser?.role?.code;

  const location = useLocation();
  const currentPageURL = location.pathname;
  const [searchParams, setSearchParams] = useSearchParams();
  const PROVIDERS = useProviders();

  const toastId = React.useRef<string | number | null>(null);

  const sortField =
    searchParams.get('__sort') || // If sort field not available in search param, set sort field by page
    (currentPageURL === PAGE_LINKS.missingInformationFinalReview
      ? SORT_FIELDS.nextAttemptSelectedDate
      : currentPageURL === PAGE_LINKS.missingInformationFailedNotifications
      ? SORT_FIELDS.referralContactNotificationFailedDate
      : SORT_FIELDS.createdAt);

  const sortDirection =
    searchParams.get('__order') || // If sort order not available in search param, set sort direction by page
    (currentPageURL === PAGE_LINKS.missingInformationFinalReview || currentPageURL === PAGE_LINKS.missingInformationFailedNotifications
      ? SORT_ORDERS.descending
      : SORT_ORDERS.ascending);

  const currentPage = +(searchParams.get('__page') || 1);
  const recordToView =
    searchParams.get('isActive') === 'false'
      ? currentPageURL === PAGE_LINKS.missingInformationFinalReview
        ? SEARCH_FIELD_MAPPER.closed
        : SEARCH_FIELD_MAPPER.received
      : currentPageURL === PAGE_LINKS.missingInformationFinalReview
      ? SEARCH_FIELD_MAPPER.open
      : SEARCH_FIELD_MAPPER.missing;

  const filteredItems = location.search
    ?.substring(1)
    .split('&')
    .filter((item) => !item.includes('__') && FILTER_BY_DROPDOWN_OPTIONS.some((filterOption) => item.includes(filterOption.value)));

  const [filterKey, filterValue] = filteredItems.length > 0 ? filteredItems[0].split('=') : [null, null];

  const downloadFile = useDownloadFile();
  const [selectedFilterByRadioOption, setFilterByRadioOption] = useState(recordToView);
  const [selectedFilterByNextAttempt, setFilterByNextAttempt] = useState(
    searchParams.get('nextAttempt') !== GENERAL_CONSTANTS.all
      ? searchParams.get('nextAttempt') || GENERAL_CONSTANTS.all
      : GENERAL_CONSTANTS.all,
  );

  const [selectedFilterByNextContactDate, setFilterByNextContactDate] = useState(
    searchParams.get('nextContactDate') !== GENERAL_CONSTANTS.all
      ? searchParams.get('nextContactDate') || GENERAL_CONSTANTS.all
      : GENERAL_CONSTANTS.all,
  );

  const [selectedFilterByNotificationStatus, setFilterByNotificationStatus] = useState(
    searchParams.get('notificationStatus') !== GENERAL_CONSTANTS.all
      ? searchParams.get('notificationStatus') || GENERAL_CONSTANTS.all
      : GENERAL_CONSTANTS.all,
  );

  const [selectedFilterByDropdownOption, setSelectedFilterByDropdownOption] = useState(filterKey || SEARCH_FIELD_MAPPER.providerName);
  const [selectedFilterByFieldValue, setSelectedFilterByFieldValue] = useState(filterValue || '');

  const [modalPopupProps, setModalPopupProps] = useState<ModalPopupContactingProps | null>(null);
  const [warningModalProps, setWarningModalProps] = useState<ModalPopupProps | null>(null);

  const [isLoadingOnTableAction, setLoadingOnTableAction] = useState(false);

  const [apiCallTriggered, setAPICallTriggered] = useState(false);

  const showToaster = (message: string, color = false) => {
    if (toastId.current !== null) {
      toast.dismiss(toastId.current);
    }

    let toasterOptions: any = { autoClose: 5000 };

    if (color) {
      toasterOptions = { ...toasterOptions, theme: 'colored' };
    }

    toastId.current = toast.error(message, toasterOptions);
  };

  const [getPatientProviderContacting, { data: apiGetData, error: apiGetError, loading: apiLoadingGet }] = useLazyQuery(
    queryPatientProviderContacting,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [getCSVForPatientProviderContacting] = useLazyQuery(queryDownloadMissingInfoContactingCSV, {
    fetchPolicy: 'no-cache',
  });

  const [updatePatientMissingInfoReceivedAll, { loading: apiLoadingUpdateReceivedAll }] = useMutation(
    mutationUpdatePatientMissingInfoReceivedAll,
    {
      onError() {
        const message =
          'There was an error with updating received all information for this Missing Information record. Please try again later or contact an administrator to troubleshoot.';
        showToaster(message, true);
      },
    },
  );

  const [downloadPatientMissingInfoPDF, { loading: loadingDownloadAPI, error: apiErrorDownloadPDF }] = useLazyQuery(
    queryDownloadPDFPatientMissingInfo,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [closePatientMissingInfoRecord, { loading: loadingCloseRecordAPI }] = useMutation(mutationClosePatientMissingInfoRecord, {
    onError() {
      const message =
        'There was an error with closing this Missing Information record. Please try again later or contact an administrator to troubleshoot.';
      showToaster(message, true);
    },
  });

  const [sendNextAttemptNotification, { loading: loadingSendNextAttemptNotificationAPI }] = useMutation(
    mutationSendNextAttemptNotification,
    {
      onError() {
        const message =
          'There was an error with triggering a notification to this Missing Information record provider. Please try again later or contact an administrator to troubleshoot.';
        showToaster(message, true);
      },
    },
  );

  const loading = false;

  const FILTER_BY_DROPDOWN_OPTIONS_PROVIDERS = useMemo(() => {
    const array = uniqBy(PROVIDERS as Provider[], 'name');

    const result = array
      .map(({ name }: Provider) => {
        const trimmedName = (name || '').trim();
        return {
          value: trimmedName,
          text: trimmedName,
          key: trimmedName,
        };
      })
      ?.sort((a: any, b: any) => a.text.localeCompare(b.text));

    return result;
  }, [PROVIDERS]);

  const FILTER_BY_DROPDOWN_OPTIONS_PROVIDER_GROUPS = useMemo(() => {
    const providerGroupNameArr: { value: string; text: string; key: string }[] = [];

    PROVIDERS.forEach(({ group }: Provider) => {
      if (group && !providerGroupNameArr.find((item) => item.text === group.originalName)) {
        const groupName = group.originalName;
        providerGroupNameArr.push({
          value: groupName,
          text: groupName,
          key: groupName,
        });
      }
    });
    return providerGroupNameArr?.sort((a: any, b: any) => a.text.localeCompare(b.text));
  }, [PROVIDERS]);

  const getSearchParams = (): SearchOptions => {
    let sortDir = sortDirection === 'ascending' ? 'ASC' : 'DESC';

    if (
      sortField === SORT_FIELDS.nextContactDate ||
      sortField === SORT_FIELDS.receivedDate ||
      sortField === SORT_FIELDS.closedDate ||
      sortField === SORT_FIELDS.nextAttemptSelectedDate ||
      sortField === SORT_FIELDS.referralContactNotificationFailedDate
    ) {
      sortDir = sortDirection === 'ascending' ? 'DESC' : 'ASC';
    }

    const skip = (currentPage - 1) * DEFAULT_NUMBER_OF_RECORDS_PER_PAGE_MISSING_INFORMATION;

    const filterOption: Record<string, any> = {
      isActive: true,
    };

    const searchedParams = transformSearchedParamEntries(searchParams);

    Object.keys(searchedParams).forEach((key) => {
      if (!key.includes('__')) {
        if (key === 'isActive') {
          filterOption[key] = searchedParams[key] === 'false' ? false : true;
        } else {
          filterOption[key] = searchedParams[key];
        }
      }
    });

    let sortFieldInfo =
      sortField === SORT_FIELDS.nextContactDate
        ? 'NEXT_CONTACT_DATE'
        : sortField === SORT_FIELDS.receivedDate
        ? 'RECEIVED_DATE'
        : sortField === SORT_FIELDS.createdAt
        ? 'CREATED_AT'
        : sortField === SORT_FIELDS.closedDate
        ? 'CLOSED_DATE'
        : sortField === SORT_FIELDS.nextAttemptSelectedDate
        ? 'NEXT_ATTEMPT_SELECTED_DATE'
        : sortField === SORT_FIELDS.referralContactNotificationFailedDate
        ? 'REFERRAL_CONTACT_NOTIFICATION_FAILED_DATE'
        : sortField;

    if (currentPageURL === PAGE_LINKS.missingInformationFinalReview) {
      filterOption.nextAttempt = 'Final Review';
    } else if (currentPageURL === PAGE_LINKS.missingInformationFailedNotifications) {
      filterOption.isReferralContactNotificationFailed = true;
    }

    if (
      currentPageURL === PAGE_LINKS.missingInformationContacting &&
      ((filterOption.nextAttempt || '') === '' || filterOption.nextAttempt === GENERAL_CONSTANTS.all)
    ) {
      filterOption.nextAttempt = 'Not Final Review';
    }
    return {
      skip: skip,
      take: DEFAULT_NUMBER_OF_RECORDS_PER_PAGE_MISSING_INFORMATION,
      sortBy: sortFieldInfo,
      sortDirection: sortDir,
      filter: filterOption,
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUpdatedPatientProviderContacting = () => {
    const searchOptions = getSearchParams();

    if (!apiCallTriggered) {
      setAPICallTriggered(true);
      getPatientProviderContacting({
        variables: {
          searchOptions: { ...searchOptions },
        },
      }).then((response) => {
        setLoadingOnTableAction(false);
        setAPICallTriggered(false);
        onWorklistUpdate(response?.data?.patientProviderContacting?.totalRecords);
      });
    }
  };

  //#region Handlers
  const handleReceivedAll = useCallback(
    async (selectedRecordId: Number) => {
      const result = await updatePatientMissingInfoReceivedAll({
        variables: {
          patientMissingInfoInput: {
            id: selectedRecordId,
          },
        },
      });

      if (result?.data?.updatePatientMissingInfoReceivedAll === 'Patient Missing info record received all updated successfully.') {
        if (toastId.current !== null) {
          toast.dismiss(toastId.current);
        }

        toastId.current = toast('Record closed.');
        setWarningModalProps(null);

        getUpdatedPatientProviderContacting();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getUpdatedPatientProviderContacting, updatePatientMissingInfoReceivedAll],
  );

  const handleClose = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, selectedRecordId: number) => {
    event.preventDefault();

    const result = await closePatientMissingInfoRecord({
      variables: {
        patientMissingInfoRecordId: selectedRecordId,
      },
    });

    if (result?.data?.closePatientMissingInfoRecord === 'Patient Missing info record closed successfully.') {
      if (toastId.current !== null) {
        toast.dismiss(toastId.current);
      }

      toastId.current = toast('Record closed.');

      setWarningModalProps(null);

      getUpdatedPatientProviderContacting();
    }
  };

  const handleSendNextAttemptNotification = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    brightreePatientId: number,
  ) => {
    event.preventDefault();

    const result = await sendNextAttemptNotification({
      variables: {
        brightreeId: +brightreePatientId,
        timezoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    });

    if (toastId.current !== null) {
      toast.dismiss(toastId.current);
    }

    let message = '';
    if (result?.data?.sendNextAttemptNotification === 'Notification sent successfully.') {
      message = 'Notification attempt has been made.';
    } else if (result?.data?.sendNextAttemptNotification === 'Notification Failed.') {
      message = 'Notification attempt failed.  See the Failed Notifications list for more details.';
    }

    if (message !== '') {
      toastId.current = toast(message);

      setWarningModalProps(null);

      getUpdatedPatientProviderContacting();

      if (refreshTabCounts) {
        refreshTabCounts();
      }
    }
  };

  const handleDownloadPDF = async (brightreePatientId: number) => {
    await downloadPatientMissingInfoPDF({
      variables: {
        brightreeId: +brightreePatientId,
        timezoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    }).then(({ error, data }: any) => {
      if (error || !data.generatePatientMissingInfoPDF) {
        const toasterErrorMessage = 'An error occurred. Please try again later or contact an administrator to troubleshoot.';
        showToaster(toasterErrorMessage, true);
        return;
      }

      downloadFile(data.generatePatientMissingInfoPDF, 'Patient_Missing_Info', 'pdf');
    });
  };

  const handleDownloadCSV = () => {
    setLoadingOnTableAction(true);

    const searchOptions = getSearchParams();
    searchOptions.take = 0;
    searchOptions.timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
    getCSVForPatientProviderContacting({
      variables: {
        searchOptions: { ...searchOptions },
      },
    }).then((response) => {
      setLoadingOnTableAction(false);

      let fileName = '';

      switch (currentPageURL) {
        case PAGE_LINKS.missingInformationContacting:
          fileName = 'CONTACTING';
          break;
        case PAGE_LINKS.missingInformationFinalReview:
          fileName = `FINAL_REVIEW`;
          break;
        case PAGE_LINKS.missingInformationFailedNotifications:
          fileName = `FAILED_NOTIFICATIONS`;
          break;
      }

      downloadFile(response?.data?.downloadMissingInformationContactingCSV, fileName, 'csv');
      return;
    });
  };
  //#endregion

  const onColumnDataRender = useCallback(
    (column: TableColumnProps, rowData: PatientProviderContactingWorklist) => {
      const { patientMissingInfoId, patient, orderingProvider, orderingProviderContactInfo, patientMissingInfo } = rowData;
      const { fullName, mrn, brightreePatientId, isTOUAccepted, betternightLink, brightreeLink } = patient;
      const {
        salesOrder,
        salesOrderLink,
        createdAt,
        submittedDate,
        nextAttempt,
        nextAttemptSelectedDate,
        firstAttemptDate,
        nextContactDate,
        receivedAllDate,
        closedDate,
        updatedAt,
        referralContactNotificationFailedDate,
        latestReferralContactHistory,
      } = patientMissingInfo;

      const statusDetails = latestReferralContactHistory?.statusDetails;

      if (column.name === 'patientNameMRN') {
        return (
          <>
            <div key={patientMissingInfoId} className="patient-details">
              <div>{fullName}</div>
              <div>
                MRN:{' '}
                <span
                  style={{ cursor: 'pointer', color: '#eb5b28', fontWeight: 700 }}
                  onClick={() => window.open(betternightLink, '_blank')}
                >
                  {mrn}
                </span>
              </div>
              <div>
                BT:{' '}
                <span
                  style={{ cursor: 'pointer', color: '#eb5b28', fontWeight: 700 }}
                  onClick={() => window.open(brightreeLink, '_blank')}
                >
                  {brightreePatientId}
                </span>
              </div>
              <div>
                SO:{' '}
                <span
                  style={{ cursor: 'pointer', color: '#eb5b28', fontWeight: 700 }}
                  onClick={() => window.open(salesOrderLink, '_blank')}
                >
                  {salesOrder}
                </span>
                {selectedFilterByRadioOption === SEARCH_FIELD_MAPPER.received ||
                selectedFilterByRadioOption === SEARCH_FIELD_MAPPER.closed ? (
                  ''
                ) : (
                  <span className="actions-items">
                    <Popup
                      content="Edit"
                      trigger={
                        <span
                          className="edit-icon"
                          onClick={() => {
                            setModalPopupProps({
                              modalFor: 'salesOrder',
                              patientMissingInfoId,
                              name: fullName,
                              mrn,
                              brightreePatientId: brightreePatientId,
                              value: salesOrder.toString(),
                              onAction: (action: 'save' | 'cancel') => {
                                setModalPopupProps(null);

                                if (action === 'save') {
                                  getUpdatedPatientProviderContacting();
                                }
                              },
                            });
                          }}
                        />
                      }
                    />
                  </span>
                )}
              </div>
              {currentPageURL === PAGE_LINKS.missingInformationContacting && (
                <div>{`ToU: ${isTOUAccepted === true ? 'Yes' : isTOUAccepted === false ? 'No' : 'Unknown'}`}</div>
              )}
            </div>
          </>
        );
      } else if (column.name === 'nextAttempt') {
        return (
          <>
            <div key={patientMissingInfoId} className="nextAttempt">
              <div>{nextAttempt}</div>
            </div>
          </>
        );
      } else if (column.name === 'referringProvider' || column.name === 'referringProviderWithSugarContactLink') {
        return (
          <div key={patientMissingInfoId} className="referringProvider">
            <div>{orderingProvider?.name || 'N/A'}</div>
            <div>{orderingProvider?.group?.originalName || 'N/A'}</div>
            {column.name === 'referringProviderWithSugarContactLink' &&
              (orderingProviderContactInfo?.sugarName ? (
                <div>
                  <span
                    style={{ cursor: 'pointer', color: '#eb5b28', fontWeight: 700 }}
                    onClick={() => window.open(orderingProviderContactInfo?.sugarContactNavigationLink, '_blank')}
                  >
                    {orderingProviderContactInfo.sugarName || 'N/A'}
                  </span>
                </div>
              ) : (
                <div>N/A</div>
              ))}
            <div>{`${orderingProviderContactInfo?.preferredCommunicationMethod || 'N/A'}: ${
              orderingProviderContactInfo?.communicationMethodDetails || 'N/A'
            }`}</div>
          </div>
        );
      } else if (column.name === 'patientMissingInfo') {
        let missingInfoCategories: string[] = [];

        type ObjKeys = keyof typeof patientMissingInfo;

        Object.keys(patientMissingInfo).forEach((key: string) => {
          const missingInfoKey = key as ObjKeys;
          if (patientMissingInfo[missingInfoKey] === true) {
            if (key.includes('demo')) {
              missingInfoCategories.push('Demographics');
            } else if (key.includes('medIns') || key.includes('auth')) {
              missingInfoCategories.push('Medical Insurance');
            } else if (key.includes('consNote')) {
              missingInfoCategories.push('Consult Notes');
            } else if (key.includes('homeStudy')) {
              missingInfoCategories.push('Order for Home Sleep Study');
            } else if (key.includes('baseStudy')) {
              missingInfoCategories.push('Baseline Sleep Study');
            } else if (key.includes('titrationStudy')) {
              missingInfoCategories.push('Titration Sleep Study');
            } else if (key.includes('pap')) {
              missingInfoCategories.push('Order for PAP Therapy');
            }
          }
        });

        return (
          <>
            <div key={patientMissingInfoId} className="patient-missing-info">
              <div className="actions-items">
                <label>{`As of: ${!submittedDate ? '' : formatDateToCustomString(submittedDate, true).split(' ')[0]}`}</label>
                <Popup
                  content="View patient's Missing Information record in new tab"
                  trigger={
                    <span
                      className="navigation-icon"
                      onClick={() =>
                        window.open(`${PAGE_LINKS.missingInformationPatient}?patientBrightreeId=${brightreePatientId}`, '_blank')
                      }
                    />
                  }
                />
              </div>
              <div>{[...new Set(missingInfoCategories)].join(', ')}</div>
              <InputButton
                text="Received All"
                type="button"
                onClick={() => {
                  setWarningModalProps({
                    title: 'CONFIRM',
                    body: MODAL_MESSAGE_BODY.RECEIVED_ALL,
                    showPopup: true,
                    actionButtons: [
                      {
                        name: 'no',
                        text: 'Cancel',
                        addCssClasses: 'btn-secondary text-transform-none',
                        onClick: () => setWarningModalProps(null),
                      },
                      {
                        name: 'yes',
                        text: 'Yes',
                        addCssClasses: 'text-transform-none',
                        onClick: () => handleReceivedAll(patientMissingInfoId),
                      },
                    ],
                  });
                }}
              />
              {currentPageURL === PAGE_LINKS.missingInformationContacting && (
                <InputButton
                  text="Download PDF"
                  type="button"
                  icon="download"
                  addCssClasses="btn-secondary download-pdf"
                  onClick={() => {
                    if (!orderingProvider || (orderingProvider.id || 0) === 0) {
                      const message = `Unable to generate PDF because the patient no longer has an ordering provider.`;
                      showToaster(message, true);
                      return;
                    }

                    handleDownloadPDF(brightreePatientId);
                  }}
                />
              )}
            </div>
          </>
        );
      } else if (column.name === 'firstAutoAttempt') {
        if (!firstAttemptDate) {
          return 'N/A';
        } else {
          const convertedDate = formatDateToCustomString(firstAttemptDate, true);
          // const days = getDaysDifference(convertedDate);
          const [date, time, meridiem] = convertedDate.split(' ');

          return (
            <>
              <div>{date}</div>
              <div>{`${time} ${meridiem}`}</div>
              {/* {days > 0 && <div>{`${days} day${days === 1 ? '' : 's'} ago`} </div>} */}
            </>
          );
        }
      } else if (column.name === 'nextContactDate') {
        return (
          <div className="actions-items">
            <label>{!nextContactDate ? '' : dateFormat(nextContactDate.toString(), DateFormat['mm/dd/yyyy'])}</label>
            {selectedFilterByRadioOption === SEARCH_FIELD_MAPPER.received ? (
              ''
            ) : (
              <Popup
                content="Edit"
                trigger={
                  <span
                    className="edit-icon"
                    onClick={() => {
                      setModalPopupProps({
                        modalFor: 'nextContactDate',
                        patientMissingInfoId,
                        name: fullName,
                        mrn,
                        brightreePatientId: brightreePatientId,
                        value: dateFormat(nextContactDate + '', DateFormat['mm/dd/yyyy']),
                        onAction: (action: 'save' | 'cancel') => {
                          setModalPopupProps(null);

                          if (action === 'save') {
                            getUpdatedPatientProviderContacting();
                          }
                        },
                      });
                    }}
                  />
                }
              />
            )}
          </div>
        );
      } else if (column.name === 'receivedDate') {
        return !receivedAllDate ? '' : formatDateToCustomString(receivedAllDate, true);
      } else if (column.name === 'nextAttemptSelectedDate') {
        return !(nextAttemptSelectedDate || createdAt) ? '' : formatDateToCustomString(nextAttemptSelectedDate || createdAt, true);
      } else if (column.name === 'closeRecord') {
        return (
          <InputButton
            text="Close Record"
            type="button"
            addCssClasses="finalReviewActionButtons"
            onClick={() => {
              setWarningModalProps({
                title: 'CONFIRM',
                body: MODAL_MESSAGE_BODY.CLOSE,
                showPopup: true,
                actionButtons: [
                  {
                    name: 'no',
                    text: 'Cancel',
                    addCssClasses: 'btn-secondary text-transform-none',
                    onClick: () => setWarningModalProps(null),
                  },
                  {
                    name: 'yes',
                    text: 'Close',
                    addCssClasses: 'text-transform-none',
                    onClick: (e) => handleClose(e, patientMissingInfoId),
                  },
                ],
              });
            }}
          />
        );
      } else if (column.name === 'closedDate') {
        return !updatedAt ? '' : formatDateToCustomString(closedDate || updatedAt, true);
      } else if (column.name === 'sendNextAttemptNotification') {
        return (
          <>
            <InputButton
              text={currentPageURL === PAGE_LINKS.missingInformationFailedNotifications ? 'Retry Attempt' : 'Send Next Attempt'}
              type="button"
              addCssClasses="finalReviewActionButtons"
              disabled={latestReferralContactHistory?.status?.toUpperCase() === 'PROCESSING'}
              onClick={() => {
                if (latestReferralContactHistory?.status?.toUpperCase() !== 'PROCESSING') {
                  setWarningModalProps({
                    title: 'CONFIRM',
                    body: MODAL_MESSAGE_BODY.SEND_NEXT_ATTEMPT_NOTIFICATION,
                    showPopup: true,
                    actionButtons: [
                      {
                        name: 'cancel',
                        text: 'Cancel',
                        addCssClasses: 'btn-secondary text-transform-none',
                        onClick: () => setWarningModalProps(null),
                      },
                      {
                        name: 'sendNotification',
                        text: 'Send',
                        addCssClasses: 'text-transform-none',
                        onClick: (e) => handleSendNextAttemptNotification(e, brightreePatientId),
                      },
                    ],
                  });
                } else {
                  const message = `Recent Next Attempt is in processing.  Hence, "Send Next Attempt" is not allowed.`;
                  showToaster(message, true);
                }
              }}
            />
            {latestReferralContactHistory?.status?.toUpperCase() === 'PROCESSING' && (
              <p className="processing-status-hint">Current attempt in progress</p>
            )}
          </>
        );
      } else if (column.name === 'referralContactNotificationFailedDate') {
        return !referralContactNotificationFailedDate ? '' : formatDateToCustomString(referralContactNotificationFailedDate, true);
      } else if (column.name === 'failedReason') {
        return statusDetails;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getUpdatedPatientProviderContacting, handleReceivedAll, selectedFilterByRadioOption],
  );

  const tableColumns: TableColumnProps[] = useMemo(() => {
    const columns: TableColumnProps[] = [
      {
        name: 'patientMissingInfoId',
        label: 'Id',
        isSortable: false,
        onColumnDataRender: onColumnDataRender,
        isVisible: false,
      },
      {
        name: 'patientNameMRN',
        label: 'Patient Details',
        isSortable: false,
        onColumnDataRender: onColumnDataRender,
        addClasses: 'width-17-percent',
      },
      {
        name: 'nextAttempt',
        label: 'Failed Attempt',
        addClasses: 'width-05-percent',
        isSortable: false,
        isVisible: currentPageURL === PAGE_LINKS.missingInformationFailedNotifications ? true : false,
        onColumnDataRender: onColumnDataRender,
      },
    ];

    if (currentPageURL === PAGE_LINKS.missingInformationContacting) {
      columns.push(
        ...[
          {
            name: 'referringProvider',
            label: 'BT Provider <br />BT Provider Group <br />Contact Method: Details',
            isSortable: false,
            onColumnDataRender: onColumnDataRender,
            addClasses: 'width-25-percent',
          },
          {
            name: 'nextContactDate',
            label: 'Next Contact Date',
            isSortable: true,
            addClasses: 'width-05-percent showUpAndDownArrow',
            isVisible: selectedFilterByRadioOption === SEARCH_FIELD_MAPPER.received ? false : true,
            onColumnDataRender: onColumnDataRender,
          },
          {
            name: 'nextAttempt',
            label: 'Next Attempt #',
            addClasses: 'width-05-percent',
            isSortable: false,
            isVisible: selectedFilterByRadioOption === SEARCH_FIELD_MAPPER.received ? false : true,
            onColumnDataRender: onColumnDataRender,
          },
          {
            name: 'patientMissingInfo',
            label: 'Missing Information',
            addClasses: 'width-25-percent',
            isSortable: false,
            isVisible: selectedFilterByRadioOption === SEARCH_FIELD_MAPPER.received ? false : true,
            onColumnDataRender: onColumnDataRender,
          },
          {
            name: 'firstAutoAttempt',
            label: 'First Auto Attempt',
            isSortable: false,
            addClasses: 'width-05-percent',
            isVisible: true,
            onColumnDataRender: onColumnDataRender,
          },
        ],
      );

      if (selectedFilterByRadioOption === SEARCH_FIELD_MAPPER.received) {
        columns.push({
          name: 'receivedDate',
          label: 'Info Received',
          addClasses: 'width-05-percent',
          isSortable: true,
          onColumnDataRender: onColumnDataRender,
        });
      } else {
        if (userRole && ['superadmin', 'missinginformation', 'accountadmin'].includes(userRole)) {
          columns.push({
            name: 'sendNextAttemptNotification',
            label: '',
            addClasses: 'width-15-percent',
            isSortable: false,
            onColumnDataRender: onColumnDataRender,
          });
        }
      }
    } else if (currentPageURL === PAGE_LINKS.missingInformationFinalReview) {
      columns.push(
        ...[
          {
            name: 'referringProvider',
            label: 'BT Provider <br />BT Provider Group <br />Contact Method: Details',
            isSortable: false,
            onColumnDataRender: onColumnDataRender,
            addClasses: 'width-25-percent',
          },
          {
            name: 'patientMissingInfo',
            label: 'Missing Information',
            addClasses: 'width-25-percent',
            isSortable: false,
            isVisible: selectedFilterByRadioOption === SEARCH_FIELD_MAPPER.open ? true : false,
            onColumnDataRender: onColumnDataRender,
          },
          {
            name: 'nextAttemptSelectedDate',
            label: 'Entered List',
            isSortable: selectedFilterByRadioOption === SEARCH_FIELD_MAPPER.open ? true : false,
            addClasses: 'width-05-percent',
            onColumnDataRender: onColumnDataRender,
          },
        ],
      );

      if (selectedFilterByRadioOption === SEARCH_FIELD_MAPPER.open) {
        columns.push({
          name: 'closeRecord',
          label: '',
          addClasses: 'width-08-percent finalReviewActionButtons',
          isSortable: false,
          onColumnDataRender: onColumnDataRender,
        });
      }

      if (selectedFilterByRadioOption === SEARCH_FIELD_MAPPER.closed) {
        columns.push({
          name: 'closedDate',
          label: 'Closed At',
          isSortable: true,
          addClasses: 'width-05-percent',
          onColumnDataRender: onColumnDataRender,
        });
      }
    } else if (currentPageURL === PAGE_LINKS.missingInformationFailedNotifications) {
      columns.push(
        {
          name: 'referringProviderWithSugarContactLink',
          label: 'BT Provider <br />BT Provider Group <br />Sugar Contact<br />Contact Method: Details',
          isSortable: false,
          onColumnDataRender: onColumnDataRender,
          addClasses: 'width-25-percent',
        },
        {
          name: 'patientMissingInfo',
          label: 'Missing Information',
          addClasses: 'width-25-percent',
          isSortable: false,
          isVisible: selectedFilterByRadioOption === SEARCH_FIELD_MAPPER.received ? false : true,
          onColumnDataRender: onColumnDataRender,
        },
        {
          name: 'failedReason',
          label: 'Failure Reason',
          addClasses: 'width-20-percent',
          isSortable: false,
          onColumnDataRender: onColumnDataRender,
        },
        {
          name: 'referralContactNotificationFailedDate',
          label: 'Entered List',
          isSortable: true,
          addClasses: 'width-08-percent',
          onColumnDataRender: onColumnDataRender,
        },
        {
          name: 'sendNextAttemptNotification',
          label: '',
          addClasses: 'width-10-percent',
          isSortable: false,
          onColumnDataRender: onColumnDataRender,
        },
        {
          name: 'closeRecord',
          label: '',
          addClasses: 'width-08-percent',
          isSortable: false,
          onColumnDataRender: onColumnDataRender,
        },
      );
    }

    return columns;
  }, [currentPageURL, onColumnDataRender, selectedFilterByRadioOption, userRole]);

  const handleSearch = async () => {
    const existingSearchedParams = transformSearchedParamEntries(searchParams);

    const newSearchParams = {
      __sort:
        selectedFilterByRadioOption === SEARCH_FIELD_MAPPER.received
          ? SORT_FIELDS.receivedDate
          : selectedFilterByRadioOption === SEARCH_FIELD_MAPPER.open
          ? SORT_FIELDS.nextAttemptSelectedDate
          : selectedFilterByRadioOption === SEARCH_FIELD_MAPPER.closed
          ? SORT_FIELDS.closedDate
          : SORT_FIELDS.createdAt,
      __order: SORT_ORDERS.ascending,
      __page: '1',

      [selectedFilterByDropdownOption]: selectedFilterByFieldValue,
      isActive: (selectedFilterByRadioOption === SEARCH_FIELD_MAPPER.missing ||
      selectedFilterByRadioOption === SEARCH_FIELD_MAPPER.open
        ? true
        : false
      ).toString(),
    };

    if (existingSearchedParams.nextAttempt) {
      newSearchParams.nextAttempt = existingSearchedParams.nextAttempt;
    }

    if (existingSearchedParams.nextContactDate) {
      newSearchParams.nextContactDate = existingSearchedParams.nextContactDate;
    }

    if (existingSearchedParams.notificationStatus) {
      newSearchParams.notificationStatus = existingSearchedParams.notificationStatus;
    }

    setSearchParams(newSearchParams);
  };

  const handleRestList = () => {
    setSearchParams();

    setFilterByRadioOption(
      currentPageURL === PAGE_LINKS.missingInformationFinalReview ? SEARCH_FIELD_MAPPER.open : SEARCH_FIELD_MAPPER.missing,
    );
    setFilterByNextAttempt(GENERAL_CONSTANTS.all);
    setFilterByNextContactDate(GENERAL_CONSTANTS.all);
    setFilterByNotificationStatus(GENERAL_CONSTANTS.all);
    setSelectedFilterByDropdownOption(SEARCH_FIELD_MAPPER.providerName);
    setSelectedFilterByFieldValue('');
  };

  useEffect(() => {
    if (Array.from(searchParams.entries()).length === 0) {
      setFilterByRadioOption(
        currentPageURL === PAGE_LINKS.missingInformationFinalReview ? SEARCH_FIELD_MAPPER.open : SEARCH_FIELD_MAPPER.missing,
      );
      setSelectedFilterByDropdownOption(SEARCH_FIELD_MAPPER.providerName);
      setSelectedFilterByFieldValue('');
      setFilterByNextAttempt(GENERAL_CONSTANTS.all);
      setFilterByNextContactDate(GENERAL_CONSTANTS.all);
      setFilterByNotificationStatus(GENERAL_CONSTANTS.all);
    }

    getUpdatedPatientProviderContacting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, currentPageURL]);

  const { totalRecords, patientProviderContacting } = apiGetData?.patientProviderContacting || {};

  if (apiGetError || apiErrorDownloadPDF) {
    const {
      graphQLErrors: [{ message }],
    } = apiGetError || apiErrorDownloadPDF || { graphQLErrors: [] };

    if (message === 'Invalid token' || message === 'Unauthorized') {
      logout();
    }
  }

  const renderFilterBySearchField = () => {
    if (selectedFilterByDropdownOption === SEARCH_FIELD_MAPPER.providerName) {
      return (
        <InputSelect
          key="filterByFieldValue"
          name="filterByFieldValue"
          addClass="filterByValueDropdown"
          inline={false}
          options={FILTER_BY_DROPDOWN_OPTIONS_PROVIDERS}
          search={true}
          selection={'selection'}
          placeholder={'Select Provider'}
          value={(selectedFilterByFieldValue || filterValue || '').replaceAll('+', ' ')}
          onChange={(_, { value }) => {
            setSelectedFilterByFieldValue(value + '');
          }}
        />
      );
    } else if (selectedFilterByDropdownOption === SEARCH_FIELD_MAPPER.providerGroup) {
      return (
        <InputSelect
          key="filterByFieldValue"
          name="filterByFieldValue"
          addClass="filterByValueDropdown"
          inline={false}
          options={FILTER_BY_DROPDOWN_OPTIONS_PROVIDER_GROUPS}
          search={true}
          selection={'selection'}
          placeholder={'Select Provider Group'}
          value={selectedFilterByFieldValue}
          onChange={(_, { value }) => {
            setSelectedFilterByFieldValue(value + '');
          }}
        />
      );
    }

    return (
      <>
        <InputField
          type="string"
          name="filterByFieldValue"
          label=""
          placeholder={`Enter Value`}
          value={selectedFilterByFieldValue}
          maxLength={50}
          inline={false}
          onChange={(event) => {
            const {
              target: { value },
            } = event;

            setSelectedFilterByFieldValue(value);
          }}
        />
      </>
    );
  };

  const renderStatusFilters = (
    <Grid.Column mobile={16} computer={4} className="filter-controls">
      <InputButton
        inline={false}
        text="CSV"
        type="button"
        addCssClasses="download-pdf"
        icon="download"
        onClick={(e) => {
          e.preventDefault();
          handleDownloadCSV();
        }}
      />
      <RadioList
        name="statusFilter"
        label=""
        options={FILTER_BY_RADIO_OPTIONS.filter((rec) => rec.page === currentPageURL)}
        value={selectedFilterByRadioOption}
        onChange={(selectedValue) => {
          const existingSearchedParams = transformSearchedParamEntries(searchParams);

          if (selectedValue === SEARCH_FIELD_MAPPER.received || selectedValue === SEARCH_FIELD_MAPPER.closed) {
            setSearchParams({
              ...existingSearchedParams,
              nextAttempt: GENERAL_CONSTANTS.all,
              nextContactDate: GENERAL_CONSTANTS.all,
              notificationStatus: GENERAL_CONSTANTS.all,
              __page: '1',
              __sort: selectedValue === SEARCH_FIELD_MAPPER.closed ? SORT_FIELDS.closedDate : SORT_FIELDS.receivedDate,
              __order: SORT_ORDERS.descending,
              isActive: 'false',
            });
          } else {
            setSearchParams({
              ...existingSearchedParams,
              nextAttempt: GENERAL_CONSTANTS.all,
              nextContactDate: GENERAL_CONSTANTS.all,
              notificationStatus: GENERAL_CONSTANTS.all,
              __page: '1',
              __sort:
                currentPageURL === PAGE_LINKS.missingInformationFinalReview && selectedValue === SEARCH_FIELD_MAPPER.open
                  ? SORT_FIELDS.nextAttemptSelectedDate
                  : SORT_FIELDS.createdAt,
              __order: SORT_ORDERS.ascending,
              isActive: 'true',
            });
          }

          setFilterByNextAttempt(GENERAL_CONSTANTS.all);
          setFilterByNextContactDate(GENERAL_CONSTANTS.all);
          setFilterByNotificationStatus(GENERAL_CONSTANTS.all);
          setFilterByRadioOption(selectedValue);
        }}
      />
    </Grid.Column>
  );

  return (
    <div className="patient-contacting-container">
      <Item as="p" className="desc">
        {note}
      </Item>

      {PAGE_LINKS.missingInformationFailedNotifications === currentPageURL && (
        <Grid className="filter-pane">
          <Grid.Row columns={2}>
            <Grid.Column mobile={16} computer={3} className="filter-controls">
              <InputButton
                inline={false}
                text={'CSV'}
                type="button"
                addCssClasses="download-csv"
                icon="download"
                onClick={(e) => {
                  e.preventDefault();
                  handleDownloadCSV();
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}

      {currentPageURL !== PAGE_LINKS.missingInformationFailedNotifications && (
        <Grid className="filter-pane">
          <Grid.Row columns={2}>
            <Grid.Column mobile={16} computer={12}>
              <Form>
                <InputSelect
                  key="filterBy"
                  name="filterBy"
                  inline={false}
                  options={FILTER_BY_DROPDOWN_OPTIONS}
                  search={false}
                  placeholder="Filter by:"
                  selection={'selection'}
                  label="Filter by:"
                  value={selectedFilterByDropdownOption}
                  onChange={(_, { value }) => {
                    setSelectedFilterByDropdownOption(value + '');
                    setSelectedFilterByFieldValue('');
                  }}
                />
                {renderFilterBySearchField()}
                <div className="action-buttons">
                  <InputButton
                    text="Filter"
                    inline={false}
                    addCssClasses="search-button"
                    disabled={!(selectedFilterByFieldValue + '').trim()}
                    onClick={handleSearch}
                  />
                  <InputButton text="Reset List" inline={false} addCssClasses="search-button" onClick={handleRestList} />
                </div>
              </Form>
            </Grid.Column>
            {(selectedFilterByRadioOption === SEARCH_FIELD_MAPPER.received ||
              currentPageURL === PAGE_LINKS.missingInformationFinalReview) &&
              renderStatusFilters}
          </Grid.Row>
          <Grid.Row>
            {selectedFilterByRadioOption === SEARCH_FIELD_MAPPER.received ||
            currentPageURL === PAGE_LINKS.missingInformationFinalReview ? (
              ''
            ) : (
              <Grid.Column>
                <RadioList
                  name="nextAttempt"
                  label="Next Attempt #"
                  addClasses="next-attempt rdo-btn-list"
                  options={FILTER_BY_NEXT_ATTEMPT}
                  value={selectedFilterByNextAttempt}
                  onChange={(selectedValue) => {
                    const existingSearchedParams = transformSearchedParamEntries(searchParams);

                    setSearchParams({
                      ...existingSearchedParams,
                      __sort: SORT_FIELDS.receivedDate,
                      __order: SORT_ORDERS.descending,
                      __page: '1',
                      nextAttempt: selectedValue,
                    });

                    setFilterByNextAttempt(selectedValue);
                  }}
                />
                <RadioList
                  name="nextContactDate"
                  label="Next Contact Date"
                  addClasses="rdo-btn-list"
                  options={FILTER_BY_NEXT_CONTACT_DATE}
                  value={selectedFilterByNextContactDate}
                  onChange={(selectedValue) => {
                    const existingSearchedParams = transformSearchedParamEntries(searchParams);

                    setSearchParams({
                      ...existingSearchedParams,
                      __sort: SORT_FIELDS.receivedDate,
                      __order: SORT_ORDERS.descending,
                      __page: '1',
                      nextContactDate: selectedValue,
                    });

                    setFilterByNextContactDate(selectedValue);
                  }}
                />
                <RadioList
                  name="notificationStatus"
                  label="Notification Status"
                  addClasses="rdo-btn-list"
                  options={FILTER_BY_NOTIFICATION_STATUS}
                  value={selectedFilterByNotificationStatus}
                  onChange={(selectedValue) => {
                    const existingSearchedParams = transformSearchedParamEntries(searchParams);

                    setSearchParams({
                      ...existingSearchedParams,
                      __sort: SORT_FIELDS.receivedDate,
                      __order: SORT_ORDERS.descending,
                      __page: '1',
                      notificationStatus: selectedValue,
                    });

                    setFilterByNotificationStatus(selectedValue);
                  }}
                />
              </Grid.Column>
            )}
            {selectedFilterByRadioOption === SEARCH_FIELD_MAPPER.missing &&
              currentPageURL === PAGE_LINKS.missingInformationContacting &&
              renderStatusFilters}
          </Grid.Row>
        </Grid>
      )}
      {(loading ||
        apiLoadingGet ||
        isLoadingOnTableAction ||
        apiLoadingUpdateReceivedAll ||
        loadingDownloadAPI ||
        loadingCloseRecordAPI ||
        loadingSendNextAttemptNotificationAPI) && <Loading show={true} />}
      <Item>
        <PPTable
          className="pp-table-secondary align-text-bottom"
          data={patientProviderContacting}
          columns={tableColumns}
          totalRecords={totalRecords}
          currentPage={currentPage}
          pageSize={DEFAULT_NUMBER_OF_RECORDS_PER_PAGE_MISSING_INFORMATION}
          sortField={
            sortField === 'RECEIVED_DATE'
              ? SORT_FIELDS.receivedDate
              : currentPageURL === PAGE_LINKS.missingInformationFinalReview && sortField === 'NEXT_ATTEMPT_SELECTED_DATE'
              ? SORT_FIELDS.nextAttemptSelectedDate
              : currentPageURL === PAGE_LINKS.missingInformationFailedNotifications &&
                sortField === 'REFERRAL_CONTACT_NOTIFICATION_FAILED_DATE'
              ? SORT_FIELDS.referralContactNotificationFailedDate
              : sortField
          }
          sortDirection={sortDirection as SortDirectionProp}
          onSort={(sortBy, sortDir) => {
            setLoadingOnTableAction(true);
            const existingSearchedParams = transformSearchedParamEntries(searchParams);

            setSearchParams({
              ...existingSearchedParams,
              __sort: sortBy,
              __order: sortDir,
              __page: '1',
            });
          }}
          onPageChange={(updatedCurrentPage) => {
            setLoadingOnTableAction(true);

            const existingSearchedParams = transformSearchedParamEntries(searchParams);

            setSearchParams({
              ...existingSearchedParams,
              __page: updatedCurrentPage.toString(),
            });
          }}
          pageCounts={TABLE_MISSING_INFO_PAGE_COUNTS}
        />
        {modalPopupProps && <ModalPopupContacting {...modalPopupProps} />}
        {warningModalProps && <ModalPopup {...warningModalProps} size="tiny" inline={false} />}
      </Item>
    </div>
  );
};

export default PatientProviderContacting;
