import { TablePageCountProps } from '../components/controls/ProviderTable/types/table-props.type';

export const PAGE_LINKS = {
  home: '/',
  loginLinkExpiration: '/login-link-expired',
  loginInstruction: '/login-instruction',
  administrators: '/account-management/administrators',
  adminUserCreate: '/account-management/administrators/new',
  adminUserEdit: '/account-management/administrators/edit',
  providers: '/account-management/providers',
  providerUserCreate: '/account-management/providers/new',
  providerUserEdit: '/account-management/providers/edit',
  patients: '/patients',
  missingInformation: '/missing-information',
  glossary: '/glossary',
  login: '/login',
  comingSoon: '/coming-soon',
  unauthorized: '/unauthorized',
  termOfUse: '/term-of-use',
  changePassword: '/change-password',
  missingInformationPatient: '/missing-information/patient',
  missingInformationContacting: '/missing-information/contacting',
  missingInformationFinalReview: '/missing-information/final-review',
  missingInformationFailedNotifications: '/missing-information/failed-notifications',
  missingInformationBTUploadErrors: '/missing-information/bt-upload-errors',
  missingInformationContactOutsideEmailOrFax: '/missing-information/contact-outside-email-fax',
  missingInformationSugarContactRequired: '/missing-information/sugar-contact-required',
  missingInformationContactDetailsRequired: '/missing-information/contact-details-required',
  missingInformationAudits: '/missing-information/audits',
  missingInformationReporting: '/missing-information/reporting',
};

export enum ROLES {
  'superadmin' = 'superadmin',
  'accountadmin' = 'accountadmin',
  'provider' = 'provider',
  'registrationadmin' = 'registrationadmin',
  'missinginformation' = 'missinginformation',
}

export const REGEX_PATTERNS = {
  name: new RegExp(/^[a-zA-Z](['. -]?[a-zA-Z]*)*$/),
  email: new RegExp(
    "^[_a-zA-Z0-9!#$%&'*+-/=?^_`{|}~;]+(\\.[_a-zA-Z0-9!#$%&'*+-/=?^_`{|}~;]+)*@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*(\\.[a-zA-Z]{2,})$",
  ),
  password: new RegExp('^(?!.*\\s)(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[~!@#$%^&*()_+])(?=.*?[0-9]).{8,}$'),
};

export const APP_DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';

export const GENERAL_CONSTANTS = {
  all: 'all',
  authToken: 'authToken',
  unexpectedErrorReachSupport: 'Something went wrong.  Please try again.  If you still see this error, please reach support team.',
};

export const SORT_ORDERS = {
  ascending: 'ascending',
  descending: 'descending',
};

const PAGE_COUNTS = {
  12: '12',
  24: '24',
  48: '48',
  50: '50',
  96: '96',
};

export const TABLE_PAGE_COUNTS: TablePageCountProps[] = [
  {
    key: PAGE_COUNTS[12],
    value: PAGE_COUNTS[12],
    text: PAGE_COUNTS[12],
  },
  {
    key: PAGE_COUNTS[24],
    value: PAGE_COUNTS[24],
    text: PAGE_COUNTS[24],
  },
  {
    key: PAGE_COUNTS[48],
    value: PAGE_COUNTS[48],
    text: PAGE_COUNTS[48],
  },
  {
    key: PAGE_COUNTS[96],
    value: PAGE_COUNTS[96],
    text: PAGE_COUNTS[96],
  },
];

export const TABLE_MISSING_INFO_PAGE_COUNTS: TablePageCountProps[] = [
  {
    key: PAGE_COUNTS[50],
    value: PAGE_COUNTS[50],
    text: PAGE_COUNTS[50],
  },
];

export const DEFAULT_NUMBER_OF_RECORDS_PER_PAGE = Number.parseInt(PAGE_COUNTS[12]);
export const DEFAULT_NUMBER_OF_RECORDS_PER_PAGE_MISSING_INFORMATION = Number.parseInt(PAGE_COUNTS[50]);
export const COUNTDOWN_SECONDS = 120; // 2 Sec

export const WORKLIST_TYPES = {
  SUGAR_CONTACT_REQUIRED: 'SUGAR_CONTACT_REQUIRED',
  CONTACT_DETAILS_REQUIRED: 'CONTACT_DETAILS_REQUIRED',
  CONTACTING: 'CONTACTING',
  FINAL_REVIEW: 'FINAL_REVIEW',
  FAILED_NOTIFICATIONS: 'FAILED_NOTIFICATIONS',
};

export const SUPPORTED_COMMUNICATION_TYPES = {
  EMAIL: 'EMAIL',
  FAX: 'FAX',
};

export const VALID_COMMUNICATION_TYPES = [SUPPORTED_COMMUNICATION_TYPES.FAX, SUPPORTED_COMMUNICATION_TYPES.EMAIL];
