import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form, Grid, GridColumn, GridRow, Icon, Item } from 'semantic-ui-react';

import { InputButton, InputCheckbox, InputDateField, InputField, TextAreaField } from '../../../components/controls';
import RadioList from '../../../components/controls/form/RadioList/RadioList';
import { PPTable, TableColumnProps } from '../../../components/controls/ProviderTable';
import { Loading, ModalPopup } from '../../../components/shared';
import { GlobalContext } from '../../../context/GlobalContext';
import { useDownloadFile } from '../../../hooks';
import {
  mutationClosePatientMissingInfoRecord,
  mutationSavePatientMissingInfo,
  mutationUpdatePatientMissingInfoReceivedAll,
  queryDownloadPDFPatientMissingInfo,
  queryPatientMissingInfo,
  queryPatientMissingInfoItems,
} from '../../../services';
import { ProviderContact } from '../../../types';
import { TABLE_MISSING_INFO_PAGE_COUNTS, VALID_COMMUNICATION_TYPES } from '../../../utilities/constants';
import { DateFormat, dateFormat, formatDateToCustomString } from '../../../utilities/sharedFunctions';
import useRefreshPatient from '../../PatientStatus/hooks/useRefreshPatient';
import { PatientMissingInfoForm } from './types/patientMissingInfoForm.type';

import './PatientMissingInfo.scss';

type PatientMissingInfoProps = {
  note: string;
  onSave: () => void;
};

const MODAL_MESSAGE_BODY = {
  RECEIVED_ALL:
    'Are you sure you want to indicate that we have now received all of the Missing Information for this Sales Order? This action will close the patient out of the Missing Information system. If so, click "Yes" and update the WIP state of the Sales Order in Brightree as needed accordingly.',
  CLOSE_FOR_CONTACTING:
    'Are you sure you want to close this Missing Information record? If so, click "Close" and update the WIP state of the Sales Order in Brightree as needed accordingly.',
  CLOSE_FOR_NON_CONTACTING:
    'Closing this record will clear out the information in the system associated with this BTID. Next time a PDF is generated for this BTID, the form will start out empty. Press "Close" to move forward with this action.',
};

const FILTER_BY_RADIO_OPTIONS = [
  { text: '1st', value: '1st' },
  { text: '2nd', value: '2nd' },
  { text: '3rd', value: '3rd' },
  { text: 'Final Review', value: 'Final Review' },
];

const CONTACT_HISTORY_DATA = [
  {
    attempt: 3,
    missingInformation: 'Demographics, Medical Insurance, Consult Notes, Order for Home Sleep Study',
    comments:
      "Thank you for your referral, for the patient to be approved by insurance to dispense the device, we need an order signed with pressure settings and a copy of the patient's sleep study.",
    attempted: formatDateToCustomString(new Date()),
    status: 'Success',
  },
  {
    attempt: 2,
    missingInformation: 'Demographics, Medical Insurance, Consult Notes, Order for Home Sleep Study',
    comments:
      "Thank you for your referral, for the patient to be approved by insurance to dispense the device, we need an order signed with pressure settings and a copy of the patient's sleep study.",
    attempted: formatDateToCustomString(new Date()),
    status: 'Success',
  },
  {
    attempt: 1,
    missingInformation: 'Demographics, Medical Insurance, Consult Notes, Order for Home Sleep Study',
    comments:
      "Thank you for your referral, for the patient to be approved by insurance to dispense the device, we need an order signed with pressure settings and a copy of the patient's sleep study.",
    attempted: formatDateToCustomString(new Date()),
    status: 'Success',
  },
];

const INITIALIZE_FORM_DATA: PatientMissingInfoForm = {
  id: 0,
  isProviderContactingRecord: false,
  submittedDate: '',

  salesOrder: '',
  nextContactDate: '',
  nextAttempt: '1st',
  comments: '',
};

type ObjKeys = keyof typeof INITIALIZE_FORM_DATA;

type Action = {
  type: string;
  payload: string | boolean | {};
};

const filterReducer = (state: typeof INITIALIZE_FORM_DATA, action: Action) => {
  if (action.type === 'reset') {
    return INITIALIZE_FORM_DATA;
  }

  if (action.type === 'patientMissingInfo') {
    return { ...state, ...(action.payload as Object) };
  }

  return { ...state, [action.type]: action.payload };
};

const PatientMissingInfo = ({ note, onSave }: PatientMissingInfoProps) => {
  const { logout, isPageInEditMode, setPageIsInEditMode } = useContext(GlobalContext);
  const toastId = React.useRef<string | number | null>(null);

  const [isComponentMounted, setIsComponentMounted] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const downloadFile = useDownloadFile();

  const [clearFilter, setClearFilter] = useState(false);
  const [brightreeId, setBrightreeId] = useState('');
  const [searchedBrightreeId, setSearchedBrightreeId] = useState('');
  const [showNoDataFound, setShowNoDataFound] = useState(false);
  const [showMissingInfo, setShowMissingInfo] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState('');

  const [formState, dispatchFieldChange] = useReducer(filterReducer, INITIALIZE_FORM_DATA);

  const [errorMessage] = useState('');
  const [errorMessageSalesOrder, setErrorMessageSalesOrder] = useState('');
  const [errorMessageNextContactDate, setErrorMessageNextContactDate] = useState('');
  const [errorMessageMissingInfoItems, setErrorMessageMissingInfoItems] = useState('');
  const [errorMessageCommonValidation, setErrorMessageCommonValidation] = useState('');

  const [showPageInViewMode, setShowPageInViewMode] = useState(false);

  const showToaster = (message: string, color = false) => {
    if (toastId.current !== null) {
      toast.dismiss(toastId.current);
    }

    let toasterOptions: any = { autoClose: 5000 };

    if (color) {
      toasterOptions = { ...toasterOptions, theme: 'colored' };
    }

    toastId.current = toast.error(message, toasterOptions);
  };

  const [
    getPatientMissingInfoItems,
    {
      data: apiPatientMissingInfoItemsData,
      error: apiErrorFetchingPatientMissingInfoItemsData,
      loading: apiLoadingPatientMissingInfoItemsData,
    },
  ] = useLazyQuery(queryPatientMissingInfoItems, {
    fetchPolicy: 'no-cache', // No cache added as query depends on the variable, refresh query is not working here.
  });

  const { triggerRefreshPatient, loadingRefreshPatient } = useRefreshPatient();

  const [getPatientMissingInfo, { data: getData, error: errorFetchingData, loading }] = useLazyQuery(queryPatientMissingInfo, {
    fetchPolicy: 'no-cache', // No cache added as query depends on the variable, refresh query is not working here.
  });

  const [savePatientMissingInfo, { data: savedData, loading: loadingCreateAPI }] = useMutation(mutationSavePatientMissingInfo, {
    onError(errors) {
      const { graphQLErrors } = errors;
      let showToasterMessage = true;

      if (graphQLErrors && graphQLErrors.length > 0) {
        graphQLErrors.forEach((error) => {
          if (error.message.toLowerCase().includes('sales order')) {
            showToasterMessage = false;
            setErrorMessageSalesOrder(error.message);
            setErrorMessageCommonValidation('See validation errors above.');
            return;
          }

          showToasterMessage = true;
          return;
        });
      } else {
        if (errors && Array.isArray(errors)) {
          showToasterMessage = true;
        }
      }

      if (showToasterMessage) {
        const saveErrorMessage =
          'There was an error with creating or saving this Missing Information record. Please try again later or contact an administrator to troubleshoot.';
        showToaster(saveErrorMessage, true);
      }
    },
  });

  const [closePatientMissingInfoRecord, { loading: loadingUpdateStatusAPI }] = useMutation(mutationClosePatientMissingInfoRecord, {
    onError() {
      const message =
        'There was an error with closing this Missing Information record. Please try again later or contact an administrator to troubleshoot.';
      showToaster(message, true);
    },
  });

  const [updatePatientMissingInfoReceivedAll, { loading: loadingUpdateReceivedAllAPI }] = useMutation(
    mutationUpdatePatientMissingInfoReceivedAll,
    {
      onError() {
        const message =
          'There was an error with updating received all information for this Missing Information record. Please try again later or contact an administrator to troubleshoot.';
        showToaster(message, true);
      },
    },
  );

  const [downloadPatientMissingInfoPDF, { loading: loadingDownloadAPI, error: apiErrorDownloadPDF }] = useLazyQuery(
    queryDownloadPDFPatientMissingInfo,
    {
      fetchPolicy: 'no-cache',
    },
  );

  function setMissingInfoFormData(patientMissingInfo: any) {
    setBrightreeId('');

    if (!patientMissingInfo) {
      setShowNoDataFound(true);
    } else {
      setShowNoDataFound(false);

      let missingInfoItems = {
        ...INITIALIZE_FORM_DATA,
      };

      if (patientMissingInfo.patientMissingInfo) {
        setShowPageInViewMode(true);
        setShowMissingInfo(true);
        missingInfoItems = { ...missingInfoItems, ...patientMissingInfo.patientMissingInfo };
        missingInfoItems.nextContactDate =
          missingInfoItems.nextContactDate && (missingInfoItems.nextContactDate || '') !== ''
            ? dateFormat(missingInfoItems.nextContactDate, DateFormat['mm/dd/yyyy'])
            : '';
        missingInfoItems.submittedDate =
          (missingInfoItems.submittedDate || '') !== ''
            ? formatDateToCustomString(missingInfoItems.submittedDate || '')?.split(',')[0]
            : '';
      }

      dispatchFieldChange({
        type: 'patientMissingInfo',
        payload: {
          ...INITIALIZE_FORM_DATA,
          ...missingInfoItems,
        },
      });
    }
  }

  const clearErrorsAndResetForm = () => {
    setErrorMessageSalesOrder('');
    setErrorMessageNextContactDate('');
    setErrorMessageMissingInfoItems('');
    setErrorMessageCommonValidation('');

    dispatchFieldChange({
      type: 'reset',
      payload: '',
    });

    setShowMissingInfo(false);
    setShowPageInViewMode(false);
    setPageIsInEditMode(false);
  };

  const isCurrentMissingInfoItemSelected = (currentMissingInfoItem: string) => {
    const missingInfoKey = currentMissingInfoItem as ObjKeys;
    return formState[missingInfoKey];
  };

  const isMissingInfoItemsSelected = () => {
    const missingItemCategories = [
      'demo',
      'medIns',
      'auth',
      'consNote',
      'homeStudy',
      'baseStudy',
      'titrationStudy',
      'papTherapy',
      'papAuth',
    ];

    let isMissingItemSelected = false;
    Object.keys(formState).forEach((key) => {
      const missingInfoKey = key as ObjKeys;
      if (missingItemCategories.some((category) => key.includes(category)) && formState[missingInfoKey]) {
        isMissingItemSelected = true;
      }
    });
    return isMissingItemSelected;
  };

  const worklistPatientInfo =
    (formState.id || 0) > 0 &&
    savedData?.savePatientMissingInfo?.patient?.brightreePatientId === +(searchParams.get('patientBrightreeId') || 0)
      ? savedData.savePatientMissingInfo
      : getData?.patientMissingInfo || {};

  const handleSearch = (event?: React.MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.preventDefault();
    }

    clearErrorsAndResetForm();

    setSearchParams({
      patientBrightreeId: searchedBrightreeId,
    });

    getPatientMissingInfo({
      variables: {
        brightreeId: +searchedBrightreeId,
      },
    }).then((response: any) => {
      setClearFilter(false);
      setMissingInfoFormData(response?.data?.patientMissingInfo);
    });
  };

  const handleDownloadPDF = async (triggerAudit = true) => {
    await downloadPatientMissingInfoPDF({
      variables: {
        brightreeId: +searchedBrightreeId,
        timezoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
        triggerAudit,
      },
    }).then(({ data, error }: any) => {
      if (error || !data.generatePatientMissingInfoPDF) {
        const toasterErrorMessage = 'An error occurred. Please try again later or contact an administrator to troubleshoot.';
        showToaster(toasterErrorMessage, true);
        return;
      }

      downloadFile(data.generatePatientMissingInfoPDF, 'Patient_Missing_Information', 'pdf');
    });
  };

  const handleSave = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    setErrorMessageSalesOrder('');
    setErrorMessageNextContactDate('');
    setErrorMessageMissingInfoItems('');
    setErrorMessageCommonValidation('');

    let isFormValid = true;
    if ((formState.salesOrder + '').trim() === '') {
      setErrorMessageSalesOrder('A Brightree sales order number is required.');
      isFormValid = false;
    }

    if (formState.isProviderContactingRecord && formState.nextAttempt !== 'Final Review') {
      // Next contact date is applicable only if Patient Provider (ordering) has contact method as Fax or Email and contact details.
      if (!formState.nextContactDate || (formState.nextContactDate + '').trim() === '') {
        setErrorMessageNextContactDate('The next contact date is required.');
        isFormValid = false;
      } else {
        const currentDate = new Date();
        const givenDate = new Date(formState.nextContactDate);
        currentDate.setHours(0, 0, 0, 0);
        givenDate.setHours(0, 0, 0, 0);
        if (givenDate < currentDate) {
          setErrorMessageNextContactDate('The next contact date must not be in the past.');
          isFormValid = false;
        }
      }
    }

    if (!isMissingInfoItemsSelected()) {
      setErrorMessageMissingInfoItems('At least one missing information item must be selected.');
      isFormValid = false;
    }

    if (!isFormValid) {
      setErrorMessageCommonValidation('See validation errors above.');
      return;
    }

    const providerId = worklistPatientInfo.orderingProvider?.id || 0;

    const patientMissingInfo = {
      ...formState,
      salesOrder: +formState.salesOrder,
      patientBrightreeId: worklistPatientInfo.patient.brightreePatientId,
      providerId: providerId,
      createdBy: undefined,
      createdAt: undefined,
      updatedBy: undefined,
      updatedAt: undefined,
      isActive: undefined,
      __typename: undefined,
    };

    delete patientMissingInfo.salesOrderLink;
    delete patientMissingInfo.submittedDate;
    delete patientMissingInfo.createdBy;
    delete patientMissingInfo.createdAt;
    delete patientMissingInfo.updatedBy;
    delete patientMissingInfo.updatedAt;
    delete patientMissingInfo.isActive;
    delete patientMissingInfo.__typename;

    const result = await savePatientMissingInfo({
      variables: {
        patientMissingInfo: {
          ...patientMissingInfo,
          nextContactDate:
            !patientMissingInfo.isProviderContactingRecord || formState.nextAttempt === 'Final Review'
              ? null
              : patientMissingInfo.nextContactDate,
        },
      },
    });

    if (result?.data?.savePatientMissingInfo) {
      if (toastId.current !== null) {
        toast.dismiss(toastId.current);
      }

      if (result?.data?.savePatientMissingInfo?.patientMissingInfo?.isProviderContactingRecord === false) {
        handleDownloadPDF(patientMissingInfo.id === 0 ? false : true);
      }

      if ((event.target as HTMLButtonElement).innerText.toLowerCase().trim() === 'save') {
        toastId.current = toast('Record saved.');
      }

      onSave();

      setMissingInfoFormData(result?.data?.savePatientMissingInfo);
      setPageIsInEditMode(false);
    }
  };

  const handleResetPage = () => {
    setSearchParams();
    clearErrorsAndResetForm();
    setBrightreeId('');
    setSearchedBrightreeId('');
    setClearFilter(true);
  };

  const handleClose = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    const result = await closePatientMissingInfoRecord({
      variables: {
        patientMissingInfoRecordId: formState.id,
      },
    });

    if (result?.data?.closePatientMissingInfoRecord === 'Patient Missing info record closed successfully.') {
      if (toastId.current !== null) {
        toast.dismiss(toastId.current);
      }

      toastId.current = toast('Record closed.');
      setShowWarningModal('');

      onSave();
      handleResetPage();
    }
  };

  const handleReceivedAll = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    const result = await updatePatientMissingInfoReceivedAll({
      variables: {
        patientMissingInfoInput: {
          id: formState.id,
        },
      },
    });

    if (result?.data?.updatePatientMissingInfoReceivedAll === 'Patient Missing info record received all updated successfully.') {
      if (toastId.current !== null) {
        toast.dismiss(toastId.current);
      }

      toastId.current = toast('Record closed.');
      setShowWarningModal('');

      onSave();
      handleResetPage();
    }
  };

  const handleRefreshClick = async (patientBetterNightUserId: number) => {
    const updatedPatient = await triggerRefreshPatient(patientBetterNightUserId, 'Patient refresh');

    if (updatedPatient && worklistPatientInfo) {
      const {
        id,
        fullName,
        mrn,
        brightreePatientId,
        betternightLink,
        brightreeLink,
        betterNightUserId,
        orderingProviderName,
        orderingProvider,
      } = updatedPatient;

      if (!worklistPatientInfo.orderingProvider) {
        worklistPatientInfo.orderingProvider = {};
      }

      if (orderingProvider) {
        worklistPatientInfo.orderingProvider = {
          ...worklistPatientInfo.orderingProvider,
          ...orderingProvider,
          originalName: orderingProviderName,
        };
      }

      worklistPatientInfo.patient = {
        id,
        fullName,
        mrn,
        brightreePatientId,
        brightreeLink,
        betternightLink,
        betterNightUserId,
      };
    }
  };

  const isContactDetailsInComplete = (providerContactDetails: ProviderContact): boolean => {
    const { preferredCommunicationMethod, communicationMethodDetails } = providerContactDetails;

    if ((preferredCommunicationMethod || '').trim() === '') {
      return true;
    }

    const isPreferredCommunicationMethodOutsideOfEmailFax = !VALID_COMMUNICATION_TYPES.includes(
      preferredCommunicationMethod.toUpperCase(),
    );

    if (!isPreferredCommunicationMethodOutsideOfEmailFax && (communicationMethodDetails || '').trim() === '') {
      return true;
    }

    return false;
  };

  const renderAddMissingInfo = (providerContactDetails?: ProviderContact) => {
    if (formState.isProviderContactingRecord && !showPageInViewMode) {
      return <></>;
    }

    if ((formState.id || 0) > 0 && formState.isProviderContactingRecord) {
      if (!worklistPatientInfo.orderingProvider) {
        return <div className="error-message">The patient no longer has an ordering provider.</div>;
      }

      if (!providerContactDetails) {
        return <div className="error-message">A contact no longer exists in Sugar for the ordering provider.</div>;
      }

      if (isContactDetailsInComplete(providerContactDetails)) {
        return <div className="error-message">Necessary contact details are now missing in Sugar for the ordering provider.</div>;
      }

      if (!VALID_COMMUNICATION_TYPES.includes(providerContactDetails.preferredCommunicationMethod.toUpperCase())) {
        return <div className="error-message">The provider's preferred contact method in Sugar is no longer set to Email or Fax.</div>;
      }
    } else {
      if (!worklistPatientInfo.orderingProvider) {
        return (
          <div className="error-message">
            <b>Add to Missing Information System</b> is only available when the patient has an ordering provider.
          </div>
        );
      }

      if (!providerContactDetails) {
        return (
          <div className="error-message">
            <b>Add to Missing Information System</b> is only available when a contact exists in Sugar for the ordering provider.
          </div>
        );
      }

      if (isContactDetailsInComplete(providerContactDetails)) {
        return (
          <div className="error-message">
            <b>Add to Missing Information System</b> is only available when all the necessary contact details exist in Sugar for the
            ordering provider.
          </div>
        );
      }

      if (!VALID_COMMUNICATION_TYPES.includes(providerContactDetails.preferredCommunicationMethod.toUpperCase())) {
        return (
          <div className="error-message">
            <b>Add to Missing Information System</b> is not available when the provider's preferred contact method in Sugar is not set
            to Email or Fax.
          </div>
        );
      }
    }

    return (
      <InputButton
        text="Add to Missing Information System"
        disabled={isPageInEditMode ? true : !formState.isProviderContactingRecord ? false : showMissingInfo}
        onClick={() => {
          setShowPageInViewMode(false);
          setShowMissingInfo(true);
          setPageIsInEditMode(true);
          setErrorMessageSalesOrder('');
          setErrorMessageNextContactDate('');
          setErrorMessageMissingInfoItems('');
          setErrorMessageCommonValidation('');

          if ((formState.id || 0) === 0) {
            dispatchFieldChange({
              type: 'reset',
              payload: '',
            });
          }

          dispatchFieldChange({
            type: 'isProviderContactingRecord',
            payload: true,
          });
        }}
      />
    );
  };

  const renderPatientMissingInfoActionButtons = () => {
    if (showPageInViewMode) {
      if (!formState.isProviderContactingRecord) {
        return <></>;
      }

      return (
        <>
          <InputButton text="Received All" type="button" onClick={() => setShowWarningModal('received-all')} />
          <InputButton
            type="button"
            text="Edit"
            onClick={() => {
              setPageIsInEditMode(true);
              setShowPageInViewMode(false);
            }}
          />
        </>
      );
    }

    return (
      <>
        <InputButton
          type="button"
          addCssClasses="btn-secondary"
          onClick={() => {
            setErrorMessageCommonValidation('');
            setErrorMessageSalesOrder('');
            setErrorMessageNextContactDate('');
            setErrorMessageMissingInfoItems('');

            setPageIsInEditMode(false);
            if ((formState.id || 0) > 0) {
              setMissingInfoFormData(worklistPatientInfo);
            } else {
              setShowMissingInfo(false);
              dispatchFieldChange({
                type: 'reset',
                payload: '',
              });
            }
          }}
          text="Cancel"
        />

        <InputButton text={`${!formState.isProviderContactingRecord ? 'Download PDF' : 'Save'}`} type="button" onClick={handleSave} />
      </>
    );
  };

  const renderErrorMessage = (errorFor: string) => {
    switch (errorFor) {
      case 'SALES_ORDER':
        return errorMessageSalesOrder && <div className="error-message">{errorMessageSalesOrder}</div>;
      case 'NEXT_CONTACT_DATE':
        return errorMessageNextContactDate && <div className="error-message">{errorMessageNextContactDate}</div>;
      case 'MISSING_INFO_ITEMS':
        return errorMessageMissingInfoItems && <div className="error-message">{errorMessageMissingInfoItems}</div>;
      case 'MISSING_INFO_ACTION':
        return errorMessageCommonValidation && <div className="error-message">{errorMessageCommonValidation}</div>;
    }

    return errorMessage;
  };

  const renderCheckbox = (missing_info_list_item: any) => {
    const isItemSelected = isCurrentMissingInfoItemSelected(missing_info_list_item.name);

    if (showPageInViewMode && !isItemSelected) {
      return;
    }

    return (
      <InputCheckbox
        key={missing_info_list_item.name}
        checked={isItemSelected}
        {...missing_info_list_item}
        onChange={(_, selectedCheckboxData) => {
          if (showPageInViewMode) {
            // Don't make any changes in view mode;
            return;
          }
          setErrorMessageMissingInfoItems('');
          setErrorMessageCommonValidation('');

          dispatchFieldChange({
            type: missing_info_list_item.name,
            payload: selectedCheckboxData.checked || false,
          });
        }}
      />
    );
  };

  const renderCheckboxList = (missing_info_list: any, isNestedList = false) => {
    return (
      <>
        {missing_info_list.map((listItem: any) => {
          const { name, text, items, showCheckbox } = listItem;
          const isMissingInfoItemSelected = !showPageInViewMode
            ? true
            : items.some(
                (item: any) =>
                  isCurrentMissingInfoItemSelected(item.name) ||
                  (item.isNestedList &&
                    [item].some((nestedList: any) =>
                      nestedList.items.some((nestedItem: any) => isCurrentMissingInfoItemSelected(nestedItem.name)),
                    )),
              );

          if (!isMissingInfoItemSelected) {
            return null;
          }

          return (
            <div key={name || text} className={`checkbox-list-container ${isNestedList ? 'nested-list' : ''}`}>
              {!showCheckbox && text && (
                <label key={text}>
                  <b>{text}</b>
                </label>
              )}
              <Item as="div" className={`checkbox-list-items`}>
                {items.map((item: any) => {
                  if (item.isNestedList) {
                    return (
                      <>
                        {item.showCheckbox && renderCheckbox(item)}
                        {renderCheckboxList([item], item.isNestedList)}
                      </>
                    );
                  } else {
                    if (!showPageInViewMode || isCurrentMissingInfoItemSelected(item.name)) {
                      return renderCheckbox(item);
                    }
                    return null;
                  }
                })}
              </Item>
            </div>
          );
        })}
      </>
    );
  };

  const renderPatientMissingInfoItems = () => {
    const { patientMissingInfoItems } = apiPatientMissingInfoItemsData || {};

    return Object.keys(patientMissingInfoItems).map((key, index) => {
      const category = patientMissingInfoItems[key as keyof typeof patientMissingInfoItems];
      return (
        <GridColumn mobile={16} tablet={5} computer={index < 1 ? 2 : index < 4 ? 3 : 5}>
          {renderCheckboxList(category)}
        </GridColumn>
      );
    });
  };

  const tableColumns: TableColumnProps[] = [
    { name: 'attempt', label: 'Attempt #', isSortable: false },
    {
      name: 'sentDate',
      label: 'Sent',
      isSortable: false,
      onColumnDataRender: (_, { createdAt }) => formatDateToCustomString(createdAt, true),
    },
    {
      name: 'status',
      label: 'Status',
      isSortable: false,
      onColumnDataRender: (_, { status }) => status,
    },
  ];

  useEffect(() => {
    getPatientMissingInfoItems();

    const patientBrightreeId = +(searchParams.get('patientBrightreeId') || 0);

    if (patientBrightreeId > 0) {
      setSearchParams({
        patientBrightreeId: patientBrightreeId.toString(),
      });

      setSearchedBrightreeId(patientBrightreeId.toString());

      getPatientMissingInfo({
        variables: {
          brightreeId: patientBrightreeId,
        },
      }).then((response: any) => {
        setIsComponentMounted(true);
        setMissingInfoFormData(response?.data?.patientMissingInfo);
      });
    } else {
      setIsComponentMounted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (errorFetchingData || apiErrorDownloadPDF || apiErrorFetchingPatientMissingInfoItemsData) {
    const {
      graphQLErrors: [{ message }],
    } = errorFetchingData || apiErrorDownloadPDF || { graphQLErrors: [] };

    if (message === 'Invalid token' || message === 'Unauthorized') {
      logout();
      // return <Navigate to={PAGE_LINKS.login} />;
    }
  }

  return (
    <div className="patient-missing-info-container">
      <div className="desc" dangerouslySetInnerHTML={{ __html: note }}></div>
      {!isComponentMounted ? (
        <Loading show={true} />
      ) : (
        <>
          <Item className="filter-pane">
            <Form>
              <InputField
                type="numberString"
                name="brightreeId"
                label="Brightree ID"
                placeholder="Enter ID"
                value={brightreeId}
                maxLength={10}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;

                  setShowNoDataFound(false);

                  if (value.trim() !== '' && (+value < 1 || value.length > 10)) {
                    return;
                  }

                  if (value.length <= 10) {
                    setBrightreeId(value);
                    setSearchedBrightreeId(value);
                  }
                }}
              />

              <InputButton
                text="Search"
                inline={false}
                addCssClasses="search-button"
                disabled={!(brightreeId + '').trim()}
                onClick={handleSearch}
              />

              <InputButton text="Clear Search" inline={false} addCssClasses="btn-secondary clear-search" onClick={handleResetPage} />
            </Form>
          </Item>
          {(loading ||
            apiLoadingPatientMissingInfoItemsData ||
            loadingRefreshPatient ||
            loadingCreateAPI ||
            loadingUpdateStatusAPI ||
            loadingUpdateReceivedAllAPI ||
            loadingDownloadAPI) && <Loading show={true} />}

          {!clearFilter && (
            <>
              <Item
                className={`${
                  showNoDataFound || (!loading && worklistPatientInfo && worklistPatientInfo.patient) ? 'patient-info-panel' : ''
                }`}
              >
                {showNoDataFound ? (
                  <div className="error-message">{`A patient with Brightree ID ${(
                    searchedBrightreeId + ''
                  ).trim()} does not exist in the Provider Portal.`}</div>
                ) : (
                  !loading &&
                  worklistPatientInfo &&
                  worklistPatientInfo.patient && (
                    <Grid>
                      <GridColumn floated="left" mobile={16} computer={11}>
                        <Grid columns={2}>
                          <GridRow>
                            <GridColumn mobile={16} computer={5}>
                              <div className="patient-data">
                                <label>Name</label>
                                <span>
                                  {worklistPatientInfo.patient.fullName}

                                  <Icon
                                    name="redo alternate"
                                    className="cursorPointer patient-refresh"
                                    onClick={() => handleRefreshClick(worklistPatientInfo.patient.betterNightUserId)}
                                  />
                                </span>

                                <label>Brightree</label>
                                <span
                                  style={{ cursor: 'pointer', color: '#eb5b28', fontWeight: 700 }}
                                  onClick={() => window.open(worklistPatientInfo.patient.brightreeLink, '_blank')}
                                >
                                  {worklistPatientInfo.patient.brightreePatientId}
                                </span>

                                <label>MRN</label>
                                <span
                                  style={{ cursor: 'pointer', color: '#eb5b28', fontWeight: 700 }}
                                  onClick={() => window.open(worklistPatientInfo.patient.betternightLink, '_blank')}
                                >
                                  {worklistPatientInfo.patient.mrn}
                                </span>
                              </div>
                            </GridColumn>
                            <GridColumn>
                              <div className="patient-data">
                                <label>Ordering Provider</label>
                                <span>{worklistPatientInfo.orderingProvider?.originalName || 'N/A'}</span>
                                <label>Provider Group</label>
                                <span>{worklistPatientInfo.orderingProvider?.group?.originalName || 'N/A'}</span>
                              </div>
                            </GridColumn>
                          </GridRow>
                        </Grid>
                      </GridColumn>
                      <GridColumn floated="right" mobile={16} computer={5} className="add-info-button-container">
                        {worklistPatientInfo.orderingProvider && (
                          <InputButton
                            text={
                              (formState.id || 0) > 0 && formState.isProviderContactingRecord
                                ? 'Download PDF'
                                : 'Open Form to Generate PDF'
                            }
                            addCssClasses="generate-pdf"
                            disabled={isPageInEditMode}
                            onClick={() => {
                              setPageIsInEditMode(true);

                              if ((formState.id || 0) > 0) {
                                if (!formState.isProviderContactingRecord) {
                                  setShowPageInViewMode(false);
                                } else {
                                  setPageIsInEditMode(false);
                                  handleDownloadPDF();
                                }
                              } else {
                                setShowMissingInfo(true);
                                setErrorMessageSalesOrder('');
                                setErrorMessageNextContactDate('');
                                setErrorMessageMissingInfoItems('');
                                setErrorMessageCommonValidation('');

                                dispatchFieldChange({
                                  type: 'reset',
                                  payload: true,
                                });
                              }
                            }}
                          />
                        )}
                        {renderAddMissingInfo(worklistPatientInfo.orderingProvider?.contactInfo)}
                      </GridColumn>
                    </Grid>
                  )
                )}
              </Item>

              {showMissingInfo && (
                <>
                  <Item className={`missing-info-panel ${showPageInViewMode ? 'view-mode' : ''}`}>
                    <Form>
                      <Grid>
                        {showPageInViewMode ? (
                          <GridRow>
                            <GridColumn className="view-mode-sales-order">
                              <div className="field">
                                <label>Missing information as of</label>
                                <span>{formState.submittedDate}</span>
                              </div>
                              <div className="field">
                                <label>Sales Order</label>
                                <span
                                  style={{ cursor: 'pointer', color: '#eb5b28', fontWeight: 700 }}
                                  onClick={() => window.open(formState.salesOrderLink, '_blank')}
                                >
                                  {formState.salesOrder}
                                </span>
                              </div>
                              {formState.isProviderContactingRecord && formState.nextAttempt !== 'Final Review' && (
                                <div className="field">
                                  <label>Next Contact Date</label>
                                  <span>{formState.nextContactDate}</span>
                                </div>
                              )}
                              <div className="field">
                                <label>Next Attempt #</label>
                                <span>{formState.nextAttempt}</span>
                              </div>
                            </GridColumn>
                          </GridRow>
                        ) : (
                          <GridRow className="sales-order-row">
                            <GridColumn floated="left" mobile={16} computer={5}>
                              <InputField
                                type="numberString"
                                name="salesOrder"
                                label="Sales Order"
                                placeholder="Enter SO ID"
                                value={formState.salesOrder}
                                maxLength={10}
                                onChange={(e) => {
                                  const {
                                    target: { name, value },
                                  } = e;
                                  setErrorMessageSalesOrder('');
                                  setErrorMessageCommonValidation('');

                                  if (value.length <= 10) {
                                    dispatchFieldChange({
                                      type: name,
                                      payload: value,
                                    });
                                  }
                                }}
                              />
                              {renderErrorMessage('SALES_ORDER')}
                            </GridColumn>
                            {formState.isProviderContactingRecord && formState.nextAttempt !== 'Final Review' && (
                              <GridColumn floated="left" mobile={16} computer={5} className="next-contact-date-section">
                                <InputDateField
                                  name="nextContactDate"
                                  AddClass="inline"
                                  label="Next Contact Date"
                                  placeholder="mm/dd/yyyy"
                                  maxDate={new Date('12/31/9999')}
                                  value={
                                    !formState.nextContactDate || formState.nextContactDate === ''
                                      ? null
                                      : new Date(formState.nextContactDate)
                                  }
                                  onChange={(value) => {
                                    setErrorMessageNextContactDate('');
                                    setErrorMessageCommonValidation('');

                                    dispatchFieldChange({
                                      type: 'nextContactDate',
                                      payload: value?.toString() || '',
                                    });
                                  }}
                                />
                                {renderErrorMessage('NEXT_CONTACT_DATE')}
                              </GridColumn>
                            )}
                            <GridColumn floated="left" mobile={16} computer={6} className="next-attempt-section">
                              <RadioList
                                name="nextAttempt"
                                label="Next Attempt #"
                                addClasses="rdo-btn-lst-next-attempt"
                                options={FILTER_BY_RADIO_OPTIONS}
                                value={!formState.nextAttempt || (formState.nextAttempt || '') === '' ? '1' : formState.nextAttempt}
                                onChange={(selectedValue) => {
                                  dispatchFieldChange({
                                    type: 'nextAttempt',
                                    payload: selectedValue?.toString() || '',
                                  });
                                }}
                              />
                            </GridColumn>
                          </GridRow>
                        )}
                        <GridRow columns={5} className="list-section">
                          {renderPatientMissingInfoItems()}
                          {renderErrorMessage('MISSING_INFO_ITEMS')}
                        </GridRow>
                        <GridRow columns={2} className="comments-section">
                          <GridColumn
                            tablet={16}
                            computer={formState.isProviderContactingRecord && (formState.id || 0) > 0 && showPageInViewMode ? 11 : 13}
                          >
                            {showPageInViewMode ? (
                              <div className="field">
                                <label>Comments</label>
                                <span>{formState.comments}</span>
                              </div>
                            ) : (
                              <TextAreaField
                                name="comments"
                                label="Comments"
                                addCssClasses="comment-field"
                                placeholder="Comments"
                                hint={`${500 - formState.comments.length} of 500 Characters remaining`}
                                maxLength={500}
                                value={formState.comments}
                                onChange={({ target: { name, value } }) => {
                                  dispatchFieldChange({
                                    type: name,
                                    payload: value,
                                  });
                                }}
                              />
                            )}
                          </GridColumn>
                          <GridColumn
                            tablet={16}
                            computer={formState.isProviderContactingRecord && (formState.id || 0) > 0 && showPageInViewMode ? 5 : 3}
                            className="actions"
                          >
                            <div className={`buttons ${!formState.isProviderContactingRecord ? 'swap-action-buttons' : ''} `}>
                              {showPageInViewMode && (
                                <InputButton text="Close Record" type="button" onClick={() => setShowWarningModal('close')} />
                              )}
                              {renderPatientMissingInfoActionButtons()}
                            </div>
                            {renderErrorMessage('MISSING_INFO_ACTION')}
                          </GridColumn>
                        </GridRow>
                      </Grid>
                    </Form>
                  </Item>
                  {worklistPatientInfo &&
                    worklistPatientInfo.patientMissingInfo?.id > 0 &&
                    worklistPatientInfo.patientMissingInfo.isProviderContactingRecord &&
                    worklistPatientInfo.contactHistories && (
                      <Item className="contact-history">
                        <label>
                          <b>Contact History</b>
                        </label>
                        <PPTable
                          className="pp-table-secondary align-text-bottom"
                          data={worklistPatientInfo.contactHistories}
                          columns={tableColumns}
                          totalRecords={CONTACT_HISTORY_DATA.length}
                          currentPage={1}
                          pageSize={50}
                          sortField=""
                          sortDirection="ascending"
                          onSort={() => {}}
                          onPageChange={() => {}}
                          pageCounts={TABLE_MISSING_INFO_PAGE_COUNTS}
                        />
                      </Item>
                    )}
                </>
              )}
            </>
          )}
        </>
      )}

      {showWarningModal !== '' && (
        <ModalPopup
          {...{
            title: 'CONFIRM',
            body:
              showWarningModal === 'close'
                ? formState.isProviderContactingRecord
                  ? MODAL_MESSAGE_BODY.CLOSE_FOR_CONTACTING
                  : MODAL_MESSAGE_BODY.CLOSE_FOR_NON_CONTACTING
                : MODAL_MESSAGE_BODY.RECEIVED_ALL,
            showPopup: true,
            actionButtons: [
              {
                name: 'no',
                text: 'Cancel',
                addCssClasses: 'btn-secondary text-transform-none',
                onClick: () => setShowWarningModal(''),
              },
              {
                name: 'yes',
                text: showWarningModal === 'close' ? 'Close' : 'Yes',
                addCssClasses: 'text-transform-none',
                onClick:
                  showWarningModal === 'close' ? handleClose : showWarningModal === 'received-all' ? handleReceivedAll : undefined,
              },
            ],
          }}
          size="tiny"
          inline={false}
        />
      )}
    </div>
  );
};

export default PatientMissingInfo;
