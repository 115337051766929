import { useMutation } from '@apollo/client';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { mutationRefreshPatient } from '../../../services';
import { Patient } from '../../../types/patient.type';

type RefreshPatientData = {
  betterNightUserId: number;
  updatedPatientData: Patient | null;
};

const useRefreshPatient = () => {
  const [refreshedPatient, setRefreshedPatient] = useState<RefreshPatientData | null>(null);

  const toastId = useRef<string | number | null>(null);

  const [refreshPatient, { loading: loadingRefreshPatient }] = useMutation(mutationRefreshPatient, {
    onError() {
      const message = `Update Failed`;

      if (toastId.current !== null) {
        toast.dismiss(toastId.current);
      }

      toastId.current = toast(message);

      if (refreshedPatient) {
        const { betterNightUserId } = refreshedPatient;

        setRefreshedPatient({
          betterNightUserId,
          updatedPatientData: null,
        });
      }
    },
  });

  const triggerRefreshPatient = async (betterNightUserId: number = 0, toasterMessagePrefix = 'Update') => {
    if (betterNightUserId === 0) {
      setRefreshedPatient(null);
      return;
    }

    const result = await refreshPatient({
      variables: {
        betterNightUserId,
      },
    });

    if (result.data) {
      const updatedPatient = result.data.refreshPatient as Patient;

      setRefreshedPatient({
        betterNightUserId,
        updatedPatientData: updatedPatient,
      });

      const message = `${toasterMessagePrefix} ${updatedPatient ? 'succeeded' : 'failed'}`;

      if (toastId.current !== null) {
        toast.dismiss(toastId.current);
      }

      toastId.current = toast(message);
      return updatedPatient;
    }

    return null;
  };

  return { refreshedPatient, triggerRefreshPatient, loadingRefreshPatient };
};

export default useRefreshPatient;
